<template>
  <div class="loader__body" :class="{'active': this.$store.state.firstLoading}">
    <div class="loader">
      <div class="loader__square"></div>
      <svg width="32" height="32" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M251.031 654.856C248.531 665.721 240.53 687.628 255.401 709.924C283.481 747.921 369.686 755.873 432.015 751.077C516.582 744.571 579.872 709.139 608.032 684.915C620.701 674.696 624.7 660.33 618.483 640.036C616.769 634.44 611.401 621.566 606.866 612.902C600.941 601.581 595.861 593.309 591.656 587.469C576.051 565.799 530.374 557.888 513.26 583.425C492.106 614.992 474.685 642.131 474.685 642.131C448.138 673.692 415.155 660.589 406.03 634.926C406.03 634.926 386.258 570.48 381.036 560.089C369.724 537.585 339.755 530.531 317.525 543.43C299.895 555.903 282.344 589.676 275.471 602.315C269.144 613.935 261.529 626.22 251.031 654.856Z" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M43.6954 359.052C33.1836 562.828 114.758 563.484 140.646 566.322C154.154 566.518 175.806 564.13 191.826 554.443C202.679 547.88 217.53 531.737 221.86 525.451C226.189 519.166 231.25 510.63 232.249 508.464C237.886 496.238 240.692 490.375 240.692 490.375C266.716 420.69 323.976 262.356 324.33 261.44C337.77 227.1 387.039 233.767 395.802 264.206C396.54 266.727 420.958 413.246 429.74 465.848C429.854 467.256 430.435 469.969 430.435 469.969C433.114 480.415 442.316 488.366 453.648 488.951C463.661 489.467 472.617 484.095 477.154 475.851C477.155 475.837 477.169 475.825 477.169 475.812C478.216 473.89 479.031 471.809 479.559 469.606L540.897 310.635C551.165 284.73 598.069 284.439 605.214 315.969C611.361 343.028 662.577 561.704 662.577 561.704C668.013 582.511 686.153 589.956 703.955 574.853C709.757 569.144 747.605 524.535 756.658 417.626C765.344 249.25 660.039 52.8973 414.612 40.2369C169.185 27.5765 49.7229 242.207 43.6954 359.052Z" fill="white"/>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-loader",
  mounted() {
    setTimeout(() => {
      this.$store.state.firstLoading = false
    }, 3500)
  }
}
</script>

<style scoped lang="scss">
.loader__body {
  position: fixed;

  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: var(--100-vh);
  background: black;
  display: flex;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: .3s;
  &.active {
    opacity: 1;
    z-index: 50;
  }
  .loader {
    position: relative;
    overflow: hidden;
  }
  .loader__square {
    width: 32px;
    height: 32px;
    background: black;
    position: absolute;
    animation: 3s loader ease-out;
    animation-delay: .3s;
    top: 0;
  }
}
@keyframes loader {
  from {
    top: 0;
  }
  to {
    top: -32px;
  }
}
</style>
