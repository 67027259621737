import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/homePage'),
    meta:{
      title: ''
    }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('@/views/teamPage'),
    meta:{
      title: 'Команда'
    }
  },
  {
    path: '/delivery',
    name: 'delivery',
    component: () => import('@/views/deliveryPage'),
    meta:{
      title: 'Доставка'
    }
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: () => import('@/views/contactsPage'),
    meta:{
      title: 'Контакты'
    }
  },
  // {
  //   path: '/reviews',
  //   name: 'reviews',
  //   component: () => import('@/views/reviewsPage'),
  //   meta:{
  //     title: 'Отзывы'
  //   }
  // },
  {
    path: '/offers',
    name: 'offers',
    component: () => import('@/views/offersPage'),
    meta:{
      title: 'Предложения'
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
