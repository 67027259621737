import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

const gsapMixin = {
  data () {
    return {
      gsap: gsap,
      ScrollTrigger: ScrollTrigger
    }
  },
  computed: {
    isPc() {
      return window.innerWidth > 768
    }
  },
  created() {
    this.gsap.registerPlugin(this.ScrollTrigger);
  }
}

export default gsapMixin
