<template>
  <v-loader></v-loader>
  <v-header></v-header>
  <main>
    <router-view v-slot="{ Component }">
      <transition :name=" $route.path != '/' ? 'fade' : '' "  mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </main>
</template>

<script>
import VHeader from "@/components/v-header";
import VLoader from "@/components/v-loader";
export default {
  components: {VLoader, VHeader},
  methods: {
    setColorsVars() {
      if (this.$route.path == '/') {
        this.$store.dispatch('setNavColors', {
          navHover: '#CE2C38',
          navHoverLang: 'black',
          background: '#000000',
          nav: 'white',
          logoFill: '#F5F5F5'
        })
      } else if (this.$route.path == '/team') {
        this.$store.dispatch('setNavColors', {
          background: '#CE2C38',
          navHover: 'black',
          navHoverLang: 'black',
          nav: 'white',
          logoFill: '#F5F5F5'
        })
      } else if (this.$route.path == '/delivery' || this.$route.path == '/contacts' || this.$route.path == '/offers') {
        this.$store.dispatch('setNavColors', {
          background: 'white',
          navHover: '#CE2C38',
          navHoverLang: '#CE2C38',
          nav: 'black',
          logoFill: '#CE2C38'
        })
      } else {
        this.$store.dispatch('setNavColors', {
          background: 'black',
          navHover: '#CE2C38',
          navHoverLang: '#CE2C38',
          nav: 'white',
          logoFill: '#F5F5F5'
        })
      }
    }
  },
  mounted() {
    this.setColorsVars()
  },
  watch: {
    $route() {
      this.setColorsVars()
    }
  }
  
}
</script>

<style lang="scss" scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
@media (max-width: 768px) {
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0s;
  }
}
</style>
