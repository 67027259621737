import { createStore } from 'vuex'
const root = document.documentElement

export default createStore({
  state: {
    homeAnimate: true,
    firstLoading: true,
  },
  mutations: {
    changeHomeAnimate(state) {
      state.homeAnimate = false
    },

  },
  actions: {
    setNavColors(_, { nav, navHover, navHoverLang, background, logoFill }) {
      nav ? root.style.setProperty('--nav', nav) : false
      navHover ? root.style.setProperty('--nav-hover', navHover) : false
      navHoverLang ? root.style.setProperty('--nav-hover-lang', navHoverLang) : false
      background ? root.style.setProperty('--body-background', background) : false
      logoFill ? root.style.setProperty('--logo-fill', logoFill) : false
    }
  },
  modules: {
  }
})
